import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Button, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"quarkly-title": "Hero-16"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"justify-content": "space-around",
			"lg-flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "50%",
			"lg-width": "100%",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"padding": "0px 80px 0px 0px",
			"lg-padding": "0px 0 0px 0px",
			"lg-margin": "0px 0px 50px 0px",
			"align-items": "flex-start"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 700 36px/1.2 --fontFamily-sans",
			"sm-font": "normal 700 42px/1.2 --fontFamily-sans",
			"children": "Зв'яжіться з нами"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 50px 0px",
			"font": "--lead",
			"color": "#8b9197",
			"children": "Щоб дізнатися більше про наші послуги або приєднатися до нашої наступної риболовлі:"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"sm-flex-direction": "column",
			"sm-align-self": "stretch"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"margin": "0px 15px 0px 0px",
			"padding": "12px 28px 12px 28px",
			"background": "--color-primary",
			"border-radius": "8px",
			"font": "normal 400 17px/1.5 --fontFamily-sans",
			"sm-margin": "0px 0 15px 0px",
			"transition": "background-color 0.2s ease 0s",
			"border-width": "2px",
			"border-style": "solid",
			"border-color": "--color-primary",
			"hover-color": "--darkL1",
			"hover-background": "rgba(63, 36, 216, 0)",
			"sm-width": "100%",
			"href": "/contacts",
			"type": "link",
			"text-decoration-line": "initial",
			"color": "--darkL2"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://megalluxo.com/img/6.jpg",
			"display": "block",
			"width": "50%",
			"object-fit": "cover",
			"height": "100%",
			"lg-width": "100%",
			"lg-height": "700px",
			"sm-height": "500px"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
				<Box {...override("box2")}>
					<Button {...override("button")}>
						Звʼязатися з нами
					</Button>
				</Box>
			</Box>
			<Image {...override("image")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;